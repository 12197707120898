<script>
  import { Storage } from '../../../config/firebase.js';

  const storageRef = Storage.ref();
  let image = null;
  function upload (event) {

    const _upload = async (file) => {
      const result = await storageRef.child('images/rivers.jpg').put(file);
      console.log("Uploaded", result);
      result.ref.getDownloadURL().then(function(downloadURL) {
	console.log('File available at', downloadURL);
	image = downloadURL;
      });
    }    
    const file = event.target.files[0];
    console.log("on change image", file);
    _upload(file);
    
  }
</script>
<div class="masonry row">
  <div class="col s12">
    <h2>Dashboard</h2>
  </div>

  <div class="col s12 m6">
    <div class="card large blue-grey darken-1">
      <div class="card-content white-text">
        <span class="card-title">Card Title</span>
        <p>
	  <input type="file" accept="image/*" on:change="{upload}">
          I am a very simple card. I am good at containing small bits of information. I am convenient because I require
          little markup to use effectively.
	  {#if image}
	    <img src="{image}" style="max-width: 100%;">
	  {/if}
        </p>
	
      </div>
      <div class="card-action">
        <a href="#">This is a link</a>
        <a href="#">This is a link</a>
      </div>
    </div>
  </div>
  <div class="col s12 m6">
    <div class="card">
      <div class="card-image">
        <img src="/images/almond.jpg" />
        <span class="card-title">Card Title</span>
      </div>
      <div class="card-content">
        <p>
          I am a very simple card. I am good at containing small bits of information. I am convenient because I require
          little markup to use effectively.
        </p>
      </div>
      <div class="card-action">
        <a href="#">This is a link</a>
      </div>
    </div>
  </div>
  <div class="col s12 m6">
    <div class="card horizontal">
      <div class="card-image">
        <img src="/images/waterfall.jpg" />
      </div>
      <div class="card-stacked">
        <div class="card-content">
          <p>I am a very simple card. I am good at containing small bits of information.</p>
        </div>
        <div class="card-action">
          <a href="#">This is a link</a>
        </div>
      </div>
    </div>
  </div>
</div>
