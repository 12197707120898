
const firebaseConfig = {
  apiKey: 'AIzaSyD46HT11gLnEqVLcmRXB-BlLlaWIi_Snvs',
  authDomain: 'youfix-bfb86.firebaseapp.com',
  databaseURL: 'https://youfix-bfb86.firebaseio.com',
  projectId: 'youfix-bfb86',
  storageBucket: 'youfix-bfb86.appspot.com',
  messagingSenderId: '548060115482',
  appId: '1:548060115482:web:a363020dcd3ffa56bc17a3',
  measurementId: 'G-ZRKE4K7XE4'
};

export { firebaseConfig };
